
  @import '../variables.scss';

  .wrapper {
    background: var(--color-theme);

    + .wrapper {
      border-top: 2px solid var(--color-border);
    }
  }

  .title {
    top: 0;
    margin: 0;
    z-index: 1;
    width: 100%;
    position: sticky;
    padding: $unit;
    font-size: inherit;
    display: flex;
    align-items: center;
    background-color: var(--color-theme);
    box-shadow: 0 2px 4px 0px rgba(var(--color-box-shadow), 0.1);
  }

  .list {
    padding: 0;
    margin: 0;
    background: var(--color-border);
  }

  .item {
    background: var(--color-theme);
    list-style-type: none;
    padding: $unit;
    display: flex;
    align-items: center;

    &.dragging {
      box-shadow: 0 4px 8px 0px rgba(var(--color-box-shadow), 0.2);
    }
  }

  .inputItem {
    background: var(--color-theme);
    list-style-type: none;
    padding: $unit;

    textarea {
      color: var(--color-text);
    }
  }

  .itemWrapper {
    flex: 1;
  }


  @import '../variables.scss';

  .entities {
    flex: 1;
    display: flex;
    margin: $unit-2 0;
    flex-direction: column;
    overflow-y: auto;

    @media screen and (max-width: $breakpoint) {
      height: initial;
      display: block;
    }
  }

  .entitySection {
    margin: 0 $unit-2 $unit-2 $unit-2;
    background-color: var(--color-theme);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: 0 0 $unit 0 rgba(var(--color-box-shadow), 0.2);
  }

  .header {
    flex: 0;
    display: flex;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    padding: $unit;
    padding-left: $unit-2;
    align-items: center;
    border-radius: 6px 6px 0 0;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-secondary) !important;
    color: white;
  }

  .transparencyBar {
    z-index: 1;
    width: 100%;
    height: $unit;
    margin: 0 $unit;
    position: fixed;
    background-color: var(--color-app-background);
  }

  .heading {
    margin: 0;
    width: 100%;
    height: 100%;
    font-size: inherit;
  }

  .content {
    padding: $unit;
  }

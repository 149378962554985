
  @import '../variables.scss';

  .lists {
    flex: 1;
    display: flex;
    margin: $unit-2;
    padding-bottom: $unit-2;
    flex-direction: column;
    background-color: var(--color-theme);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    box-shadow: 0 0 $unit 0 rgba(var(--color-box-shadow), 0.2);

    @media screen and (max-width: $breakpoint) {
      display: block;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $unit;
    padding-left: $unit-2;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-secondary) !important;
    color: white;
    flex: 0;
  }

  .listOfLists {
    overflow-y: auto;
    flex: 1;
    padding: 0;
    margin: 0;
  }

  .heading {
    margin: 0;
    font-size: inherit;
  }
